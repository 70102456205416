@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}
.rainbow {
  position: relative;
  z-index: 0;
  overflow: hidden;
}
.rainbow::before {
  content: '';
  position: absolute;
  z-index: -2;
  left: -450%;
  top: -450%;
  width: 1000%;
  height: 1000%;
  background-color: #399953;
  background-repeat: no-repeat;
  background-size: 50% 50%, 50% 50%;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-image: linear-gradient(#4285F4, #4285F4), linear-gradient(#34A853, #34A853), linear-gradient(#FBBC05, #FBBC05), linear-gradient(#EA4335, #EA4335);
  animation: rotate 4s linear infinite;
}
.rainbow::after {
  content: '';
  position: absolute;
  z-index: -1;
  left: 3px;
  top: 3px;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  background: white;
}
