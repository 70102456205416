.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 20px;
}

.grid .title {
    background-color: #999;
    color: #fff;
    padding: 1rem;
}

.grid .details {
    padding: 1rem;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.7);
    -moz-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.7);
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.7);
}

.grid .details .norm {
    position: relative;
    padding-left: 1.5rem;
    margin: 1rem;
}
.grid .details .spacing {
    margin: 2rem;
}
.grid .details .tip {
    font-weight: 700;
}

.grid .details .positive:before {
    content: '';
    position: absolute;
    width: 1rem;
    height: 1rem;
    background: linear-gradient(45deg, green, transparent);
    top: 0.6rem;
    left: 0;
    transform: translateY(-50%);
    border-radius: 50%;
}

.grid .details .negative:before {
    content: '';
    position: absolute;
    width: 1rem;
    height: 1rem;
    background: linear-gradient(45deg, red, transparent);
    top: 0.6rem;
    left: 0;
    transform: translateY(-50%);
    border-radius: 50%;
}

.grid .details .inbetween:before {
    content: '';
    position: absolute;
    width: 1rem;
    height: 1rem;
    background: linear-gradient(45deg, orange, transparent);
    top: 0.6rem;
    left: 0;
    transform: translateY(-50%);
    border-radius: 50%;
}
