.loader {
    float: left;
    margin-left: 12px;
    margin-top: 12px;
    border-right: 0.1em solid black;
    border-radius: 100%;
    -webkit-animation: loader 800ms linear infinite;
    animation: loader 800ms linear infinite;
}

.loader:before,
.loader:after {
    content: '';
    width: 0.8em;
    height: 0.8em;
    display: block;
    position: absolute;
    top: calc(50% - 0.4em);
    left: calc(50% - 0.4em);
    border-left: 0.08em solid black;
    border-radius: 100%;
    animation: loader 400ms linear infinite reverse;
}

.loader:after {
    width: 0.6em;
    height: 0.6em;
    top: calc(50% - 0.3em);
    left: calc(50% - 0.3em);
    border: 0;
    border-right: 0.05em solid black;
    -webkit-animation: none;
    animation: none;
}

@-webkit-keyframes loader {
    from {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    to {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

@keyframes loader {
    from {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    to {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

.lds-dual-ring,
.lds-dual-ring:after {
    box-sizing: border-box;
}
.lds-dual-ring {
    display: inline-block;
    width: 20px;
    height: 20px;
}
.lds-dual-ring:after {
    content: ' ';
    display: block;
    width: 16px;
    height: 16px;
    margin: 2px;
    border-radius: 50%;
    border: 2px solid currentColor;
    border-color: currentColor transparent currentColor transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
